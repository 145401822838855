@import "https://fonts.googleapis.com/css2?family=Russo+One&display=swap";
@import "https://fonts.googleapis.com/css2?family=Archivo+Narrow&display=swap";
@import "https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:wght@900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@600;900&family=Oswald:wght@600&family=Roboto+Slab:wght@300&display=swap";
@import "https://fonts.googleapis.com/css2?family=Poppins:wght@900&display=swap";
:root {
  font-family: Archivo Narrow, sans-serif;
  font-size: 16px;
}

.heading-xl {
  -webkit-text-stroke: 1px var(--color-black);
  color: #0000;
  cursor: default;
  font-family: Poppins, sans-serif;
  font-size: 148px;
  line-height: 120px;
  transition: color .5s linear;
}

.heading-xl:hover {
  -webkit-text-stroke: var(--color-black);
  color: var(--color-black);
}

.heading-sm, .heading-md {
  font-family: Montserrat, sans-serif;
  font-weight: 900;
}

.heading-md {
  font-size: 40px;
  line-height: 48px;
}

.heading-sm {
  font-size: 32px;
  line-height: 40px;
}

.uppercase-text {
  text-transform: uppercase;
}

.icon {
  width: 36px;
}

.menu-list a {
  -webkit-text-stroke: 1px var(--color-black);
  color: #0000;
  text-transform: uppercase;
  padding-right: var(--gap-md);
  transform-origin: 100%;
  font-family: Poppins, sans-serif;
  font-size: 14vw;
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
  line-height: 10.8333vw;
  text-decoration: none;
  transition: transform .5s linear, color .5s linear;
  display: block;
  overflow: hidden;
  transform: rotateY(-30deg);
}

.menu-list a:hover {
  -webkit-text-stroke: 1px fff;
  color: var(--color-black);
  transform: rotateY(-15deg);
}

.menu-list a:hover ~ a {
  color: #0000;
  transform: rotateY(-30deg);
}

p, .scroll-down span {
  padding: var(--gap-sm) 0;
  font-size: 1.3rem;
}

p {
  line-height: 1.8rem;
}

.contacts-content h2 {
  padding: var(--gap-sm);
  margin: 0;
  font-family: Montserrat, sans-serif;
  font-size: 28px;
  font-weight: 900;
}

.contacts-content h2:hover {
  background-color: var(--color-red);
  color: #fff;
  transition: background-color .3s, color .3s;
}

.contacts-content h3 {
  margin-left: .5rem;
  margin-top: var(--gap-lg);
  font-family: Montserrat, sans-serif;
  font-size: 28px;
  font-weight: 600;
}

.scroll-skills > h3 {
  color: var(--color-red);
  margin: .5rem 0;
  padding: .3rem;
  font-family: Montserrat, sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  transition: color .3s, background-color .3s;
}

.scroll-skills > h3:hover {
  color: #fff;
  background-color: var(--color-red);
}

.scroll-skills > h3 span {
  color: var(--color-black);
  font-family: Archivo Narrow, sans-serif;
  font-size: 1.2rem;
  font-weight: 200;
}

.language-content h2, .language-content h3, .project-name h3 {
  font-family: Montserrat, sans-serif;
}

.language-content h2 {
  margin-left: 1rem;
  font-size: 28px;
}

.language-content h3 {
  margin-left: auto;
  font-size: 28px;
}

.language-content h2 {
  font-weight: 900;
}

.language-content h3 {
  font-weight: 600;
}

.skills-header {
  width: 100%;
  height: var(--gap-xl);
}

.skills-header h1 {
  -webkit-text-stroke: 1px var(--color-black);
  color: #0000;
  cursor: default;
  font-family: Poppins, sans-serif;
  font-size: 80px;
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
  line-height: 80px;
}

.timeline-content h2 {
  padding: var(--gap-sm);
  margin: 0;
  font-family: Montserrat, sans-serif;
  font-size: 28px;
  font-weight: 900;
}

.timeline-content h2:hover {
  background-color: var(--color-red);
  color: #fff;
  transition: background-color .3s, color .3s;
}

.project-name h3 {
  margin: 0;
  font-size: 24px;
}

.project-date, .project-technologies, .project-links a {
  color: var(--color-black);
  font-size: 1.3rem;
}

@media screen and (max-width: 767px) {
  .heading-xl {
    font-size: 17.4707vw;
    line-height: 14.0808vw;
  }

  .heading-md {
    font-size: 5.21512vw;
    line-height: 6.25815vw;
  }

  .menu-list a {
    padding-right: 0;
    font-size: 21.9035vw;
    line-height: 16.9492vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .heading-xl {
    font-size: 13.4545vw;
    line-height: 10.9091vw;
  }

  .heading-md {
    font-size: 4vw;
    line-height: 4.8vw;
  }
}

@media screen and (min-width: 768px) {
  .icon {
    margin-top: var(--gap-md);
    width: 60px;
    transition: transform .3s;
  }

  .icon:hover {
    transform: scale(1.3);
  }

  .skills-header {
    height: 160px;
  }

  .skills-header h1 {
    font-size: 132px;
    line-height: 130px;
  }

  .terrorist-state-language {
    font-size: .5rem;
  }
}

@media screen and (min-width: 992px) {
  .skills-header {
    width: 160px;
    height: 100%;
    writing-mode: vertical-lr;
    text-orientation: mixed;
  }

  .skills-header h1 {
    -webkit-text-stroke: 1px var(--color-black);
    color: #0000;
    text-transform: uppercase;
    cursor: default;
    font-family: Poppins, sans-serif;
    font-size: 132px;
    font-style: normal;
    font-weight: 900;
    font-stretch: normal;
    line-height: 130px;
    transform: rotate(180deg);
  }
}

@media screen and (min-width: 1024px) {
  .menu-list a {
    font-size: 168px;
    line-height: 130px;
  }
}

@media screen and (min-width: 1200px) {
  .skills-header {
    width: 180px;
  }
}

/*# sourceMappingURL=index.088a4c0a.css.map */
