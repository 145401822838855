/* ticker */
@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");

/* plain text */
@import url("https://fonts.googleapis.com/css2?family=Archivo+Narrow&display=swap");

/*  big headers */
@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:wght@900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600;900&family=Oswald:wght@600&family=Roboto+Slab:wght@300&display=swap");

/* main header */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@900&display=swap");

:root {
  font-family: "Archivo Narrow", sans-serif;
  font-size: 16px;
}

.heading-xl {
  font-family: "Poppins", sans-serif;
  font-size: 148px;
  line-height: 120px;
  -webkit-text-stroke: 1px var(--color-black);
  color: transparent;
  transition: color 0.5s linear;
  cursor: default;
}

.heading-xl:hover {
  -webkit-text-stroke: var(--color-black);
  color: var(--color-black);
}

.heading-sm,
.heading-md {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
}

.heading-md {
  font-size: 40px;
  line-height: 48px;
}

.heading-sm {
  font-size: 32px;
  line-height: 40px;
}

.uppercase-text {
  text-transform: uppercase;
}

.icon {
  width: 36px;
}

.menu-list a {
  text-decoration: none;
  display: block;
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-size: calc(100vw * 168 / 1200);
  line-height: calc(100vw * 130 / 1200);
  font-style: normal;
  font-stretch: normal;
  -webkit-text-stroke: 1px var(--color-black);
  color: transparent;
  text-transform: uppercase;
  overflow: hidden;
  transform: rotateY(-30deg);
  padding-right: var(--gap-md);
  transform-origin: right center;
  transition: transform 0.5s linear, color 0.5s linear;
}

.menu-list a:hover {
  -webkit-text-stroke: 1px fff;
  color: var(--color-black);
  transform: rotateY(-15deg);
}

.menu-list a:hover ~ a {
  transform: rotateY(-30deg);
  color: transparent;
}

p,
.scroll-down span {
  font-size: 1.3rem;
  padding: var(--gap-sm) 0;
}

p {
  line-height: 1.8rem;
}

.contacts-content h2 {
  font-weight: 900;
  margin: 0;
  padding: var(--gap-sm);
  font-size: 28px;
  font-family: Montserrat, sans-serif;
}

.contacts-content h2:hover {
  background-color: var(--color-red);
  color: white;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.contacts-content h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 28px;
  font-weight: 600;
  margin-left: 0.5rem;
  margin-top: var(--gap-lg);
}

.scroll-skills > h3 {
  margin: 0.5rem 0;
  padding: 0.3rem;
  font-family: "Montserrat", sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--color-red);
  transition: color 0.3s ease, background-color 0.3s ease;
}

.scroll-skills > h3:hover {
  color: white;
  background-color: var(--color-red);
}

.scroll-skills > h3 span {
  font-family: "Archivo Narrow", sans-serif;
  font-size: 1.2rem;
  font-weight: 200;
  color: var(--color-black);
}

.language-content h2,
.language-content h3,
.project-name h3 {
  font-family: "Montserrat", sans-serif;
}

.language-content h2 {
  font-size: 28px;
  margin-left: 1rem;
}

.language-content h3 {
  font-size: 28px;
  margin-left: auto;
}

.language-content h2 {
  font-weight: 900;
}

.language-content h3 {
  font-weight: 600;
}

.skills-header {
  width: 100%;
  height: var(--gap-xl);
}

.skills-header h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-size: 80px;
  font-style: normal;
  font-stretch: normal;
  -webkit-text-stroke: 1px var(--color-black);
  color: transparent;
  line-height: 80px;
  cursor: default;
}

.timeline-content h2 {
  font-weight: 900;
  margin: 0;
  padding: var(--gap-sm);
  font-size: 28px;
  font-family: Montserrat, sans-serif;
}

.timeline-content h2:hover {
  background-color: var(--color-red);
  color: white;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.project-name h3 {
  font-size: 24px;
  margin: 0;
}

.project-date,
.project-technologies,
.project-links a {
  font-size: 1.3rem;
  color: var(--color-black);
}

@media screen and (max-width: 767px) {
  .heading-xl {
    font-size: calc(100vw * 134 / 767);
    line-height: calc(100vw * 108 / 767);
  }

  .heading-md {
    font-size: calc(100vw * 40 / 767);
    line-height: calc(100vw * 48 / 767);
  }

  .menu-list a {
    font-size: calc(100vw * 168 / 767);
    line-height: calc(100vw * 130 / 767);

    padding-right: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .heading-xl {
    font-size: calc(100vw * 148 / 1100);
    line-height: calc(100vw * 120 / 1100);
  }

  .heading-md {
    font-size: calc(100vw * 40 / 1000);
    line-height: calc(100vw * 48 / 1000);
  }
}

@media screen and (min-width: 320px) {
}

@media screen and (min-width: 392px) {
}

@media screen and (min-width: 768px) {
  .icon {
    margin-top: var(--gap-md);
    width: 60px;
    transition: transform 0.3s ease;
  }

  .icon:hover {
    transform: scale(1.3);
  }

  .skills-header {
    height: 160px;
  }

  .skills-header h1 {
    font-size: 132px;
    line-height: 130px;
  }

  .terrorist-state-language {
    font-size: 0.5rem;
  }
}

@media screen and (min-width: 992px) {
  .skills-header {
    width: 160px;
    height: 100%;
    writing-mode: vertical-lr;
    text-orientation: mixed;
  }

  .skills-header h1 {
    transform: rotate(180deg);
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-size: 132px;
    font-style: normal;
    font-stretch: normal;
    -webkit-text-stroke: 1px var(--color-black);
    color: transparent;
    line-height: 130px;
    text-transform: uppercase;
    cursor: default;
  }
}

@media screen and (min-width: 1024px) {
  .menu-list a {
    font-size: 168px;
    line-height: 130px;
  }
}

@media screen and (min-width: 1200px) {
  .skills-header {
    width: 180px;
  }
}
